<template>
    <div>
        <new-location-full-card 
            v-if="locationFullCardFlag"
            :location="locationFullCardData"
            @close="locationFullCardFlag = false"
            @updateLocations="updateLocations"
        />

        <delete-location-form 
            v-if="deleteLocationFormFlag"
            :location="deleteLocationFormData"
            @close="deleteLocationFormFlag = false"
            @deleteLocation="deleteLocation($event)"
            @updateLocations="updateLocations" 
        />

        <new-location-form-n 
            v-if="newLocationFormFlag"
            @close="newLocationFormFlag = false"
            @updateLocations="updateLocations"
        />

        <menu-bar 
            @addLocation="newLocationFormFlag = true"
        />

        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[60px]">
            <div class="text-white">
                <span class="text-[28px] font-semibold">{{ locations.length }}</span> локацій
            </div>
        </div>

        <div class="w-[90%] mx-auto mt-3 rounded-[10px] overflow-hidden">
            <table class="w-full">
                <thead class="">
                    <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                        <th class="px-3 py-2 text-start font-medium text-sm w-[4%]" v-if="screenWidth > 1500"></th>
                        <th class="px-3 py-2 text-start font-medium text-sm w-[26%]">Назва та адреса локації</th>
                        <th class="px-3 py-2 text-start font-medium text-sm w-[15%]">Коротка назва</th>
                        <th class="px-3 py-2 text-start font-medium text-sm w-[20%]">Опис</th>
                        <th class="px-3 py-2 text-start font-medium text-sm w-[35%]"></th>
                    </tr>
                </thead>
                <tbody>
                    <location-row
                        v-if="locations.length > 0"
                        v-for="location in locations" @openLocationCard="selectLocation(location)" class="mx-2" :location="location"
                        @delete="deleteLocationFormData = location; deleteLocationFormFlag = true"
                    />
                </tbody>
            </table>
        </div>
        <div class="w-full mt-[14px] flex items-center justify-center" v-if="locations.length == 0">
            <p class="text-[#A3A3C2] text-[16px]">Локації не знайдено</p>
        </div>  
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'

    import { getAllLocations, deleteCurrentLocation } from '@/services/apiRequests'

    import MenuBar from './components/MenuBar.vue'
    import NewLocationForm from './components/NewLocationForm.vue'
    import LocationCard from './components/LocationCard.vue'
    import LocationFullCard from './components/LocationFullCard.vue'
    import LocationRow from './components/LocationRow.vue'
    import NewLocationFullCard from './components/NewLocationFullCard.vue'
    import NewLocationFormN from './components/NewLocationFormN.vue'
    import DeleteLocationForm from './components/DeleteLocationForm.vue'

    export default {
        name: 'locations',

        setup(props, { emit }) {
            const newLocationFormFlag = ref(false)
            const locationFullCardFlag = ref(false)
            const deleteLocationFormFlag = ref(false)
            const deleteLocationFormData = ref({})
            const locationFullCardData = ref({})
            const locations = ref([])

            const screenWidth = ref(window.innerWidth)

            const selectLocation = (location) => {
                locationFullCardFlag.value = true
                locationFullCardData.value = location
            }

            const updateLocations = async () => {
                const res = await getAllLocations()
                locations.value = res.data
            }

            const deleteLocation = async () => {
                deleteLocationFormFlag.value = false
                await deleteCurrentLocation(deleteLocationFormData.value.location_id)
                await updateLocations()
            }

            onMounted(async () => {
                await updateLocations()

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            return {
                newLocationFormFlag,
                locations,
                locationFullCardFlag,
                locationFullCardData,
                selectLocation,
                updateLocations,
                deleteLocationFormFlag,
                deleteLocationFormData,
                deleteLocation,
                screenWidth
            }
        },

        components: {
            MenuBar,
            NewLocationForm,
            LocationCard,
            LocationFullCard,
            LocationRow,
            NewLocationFullCard,
            NewLocationFormN,
            DeleteLocationForm
        }
    }
</script>

<template>
    <div style="background-color: rgba(53, 53, 53, 0.10)" class="w-[100%] top-[0] ml-[-20px] h-full backdrop-blur-[7px] fixed z-[120]"></div>
    <div v-if="loadingSpinnerFlag" class="z-[150] fixed w-full h-full top-[50%] left-[50%] ml-[100px]">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#BD252C] border-t-transparent "></div>
    </div>
    <div v-else class="bg-[#211C1F] w-[254px] fixed top-[50%] left-[50%] ml-[110px] border-[1px] border-[#FFF] border-opacity-10 translate-x-[-50%] translate-y-[-50%] z-[150] px-[16px] py-5 rounded-[10px] select-none">
        <!-- <div class="absolute top-[10px] right-[10px]">
            <button class="float-right relative" @click="$emit('close')">
                <img src="@/assets/icons/cross_icon.svg" class="w-3 h-3">
            </button>
        </div>
        <div class="flex justify-center"><img src="../../../assets/logo.svg" class="h-[70px]"></div>
        <div class="font-semibold text-center text-[#FFF] mt-[13px] text-[15px]">
            Створити подію
        </div>

        <div class="mt-5">
            <div>
                <div class="text-center text-[#177E89] text-[13px] pr-[9px]">
                    Вид події
                </div>
                <div class="flex justify-center items-center">
                    <select v-model="trainingType" class="bg-[#D9D9D9] text-center text-[#000] text-[13px] py-[6px] outline-none border-[1px] border-[#FFF] border-opacity-10 placeholder-gray-500 rounded-[6px] p-1 w-full disabled:opacity-40" >
                        <option value="none" class="bg-[#D9D9D9]">Не задано</option>
                        <option value="training" class="bg-[#D9D9D9]">Тренування</option>
                        <option value="gym" class="bg-[#D9D9D9]">Спортзал</option>
                        <option value="competition" class="bg-[#D9D9D9]">Змагання</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="mt-[12px]">
            <div>
                <div class="text-center text-[13px] text-[#177E89] pr-[9px]">
                    Дата
                </div>
                <div class="flex justify-center items-center">
                    <input :disabled="addCurrentDateFlag" v-model="changeDate" class="bg-[#D9D9D9] text-center text-[#000] outline-none border-[1px] border-[#FFF] border-opacity-10 placeholder-gray-500 rounded-[6px] px-1 py-[2px] w-full disabled:opacity-40" type="date">
                </div>
            </div>
        </div>

        <div class="mt-[12px]">
            <div class="text-center text-[13px] text-[#177E89]">
                Час тренування
            </div>
            <div class="flex justify-center items-center">
                <input v-model="trainingTime.start" class="bg-[#D9D9D9] border-[1px] border-[#FFF] border-opacity-10 outline-none text-[#000] placeholder-gray-500 rounded-[6px] px-1 py-[2px]" type="time">
                <span class="font-bold mx-2 text-[#D9D9D9]"><img src="../../../assets/icons/divider-arrow.svg" alt=""></span>
                <input v-model="trainingTime.end" class="bg-[#D9D9D9] border-[1px] border-[#FFF] border-opacity-10 outline-none text-[#000] placeholder-gray-500 rounded-[6px] px-1 py-[2px]" type="time">
            </div>
        </div>

        <div class="mt-[12px]">
            <div>
                <div class="text-center text-[#177E89] text-[13px] pr-[9px]">
                    Повторення
                </div>
                <div class="">
                    <multi-select 
                        @update="trainingRepeat = $event"
                        @enable="addCurrentDateFlag = false"
                        @disable="addCurrentDateFlag = true"
                    />
                </div>
            </div>
        </div>

        <div class="mt-[12px]">
            <div>
                <div class="text-center text-[#177E89] text-[13px]">
                    Група
                </div>
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <input
                            @change="changeGroupText($event.target.value)"
                            :value="groupText"
                            @focus="groupOptionsShow = true"
                            @blur="groupOptionsShow = false" 
                            class="bg-[#D9D9D9] text-center text-[#000] border-[1px] border-[#FFF] border-opacity-10 outline-none placeholder-gray-500 rounded-[6px] px-1 py-[2px] w-full" 
                            type="text"
                        />
                        <div v-if="groupOptionsShow" class="overflow-auto border-[0.4px] border-[#000] absolute max-h-[100px] w-[270px] bg-[#D9D9D9]">
                            <div v-for="groupOption in groupVisibleOptions" @mousedown="setGroup(groupOption)" class="w-full px-1 hover:bg-[#c7c7c7] truncate bg-[#D9D9D9] cursor-pointer text-[#000]">{{groupOption.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[12px]">
            <div>
                <div class="text-center text-[13px] text-[#177E89]">
                    Локація
                </div>
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <input
                            @change="changeLocationText($event.target.value)"
                            :value="locationText"
                            @focus="locationOptionsShow = true"
                            @blur="locationOptionsShow = false" 
                            class="bg-[#D9D9D9] border-[1px] border-[#FFF] border-opacity-10 outline-none text-[#000] placeholder-gray-500 rounded-[6px] px-1 py-[2px] w-full" 
                            type="text"
                        />
                        <div v-if="locationOptionsShow" class="overflow-auto border-[0.4px] border-[#000] absolute max-h-[100px] w-[257px] bg-[#D9D9D9]">
                            <div v-for="locationOption in locationVisibleOptions" @mousedown="setLocation(locationOption)" class="w-full truncate px-1 hover:bg-[#c7c7c7] bg-[#D9D9D9] text-[#000] cursor-pointer">{{locationOption.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[12px]">
            <div>
                <div class="text-center text-[13px] text-[#177E89]">
                    Опис
                </div>
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <textarea class="bg-[#D9D9D9] border-[1px] border-[#FFF] border-opacity-10 outline-none text-[#000] placeholder-gray-500 rounded-[6px] px-1 py-[2px] w-[256px]" rows="5"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <button @click="sendData" class="bg-[#FECC00] text-[#211C1F] text-center w-full rounded-[6px] px-1 py-[6px] text-[13px] hover:bg-opacity-90">Створити</button>
        </div> -->

        <div class="">
            <div>
                <div class="flex justify-center items-center">
                    <div class="border-[#8C8C8C] bg-[#474849] bg-opacity-20 border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">
                        <img v-if="trainingType == 'training'" src="@/assets/icons/green_ball.svg" class="h-[18px] relative right-[2px]">
                        <img v-else-if="trainingType == 'gym'" src="@/assets/icons/red_dumbbell.svg" class="h-[18px]">
                        <img v-else-if="trainingType == 'competition'" src="@/assets/icons/gold_cup.svg" class="h-[18px]">
                        <img v-else src="@/assets/icons/green_ball.svg" class="h-[18px] relative right-[2px]">
                        <select v-model="trainingType" class="bg-transparent text-[#fff] outline-none text-center  placeholder-gray-500 py-[3px] w-full disabled:opacity-40" >
                            <option value="null" class="bg-[#211C1F]">Не задано</option>
                            <option value="training" class="bg-[#211C1F]">Тренування</option>
                            <option value="gym" class="bg-[#211C1F]">Спортзал</option>
                            <option value="competition" class="bg-[#211C1F]">Змагання</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center bg-[#474849] bg-opacity-20">
                    <input :disabled="addCurrentDateFlag" v-model="changeDate" class="custom-date-input bg-transparent text-[#fff] text-center outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[1px] px-[3px] w-full disabled:opacity-40" type="date">
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div class="flex justify-center items-center">
                <div class="bg-[#474849] bg-opacity-20">
                    <input v-model="trainingTime.start" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                </div>
                <span class="font-bold mx-2 text-[#177E89]">></span>
                <div class="bg-[#474849] bg-opacity-20">
                    <input v-model="trainingTime.end" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="">
                    <multi-select 
                        @update="trainingRepeat = $event"
                        @enable="addCurrentDateFlag = false"
                        @disable="addCurrentDateFlag = true"
                    />
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="bg-[#474849] bg-opacity-20">
                            <input
                                @change="changeGroupText($event.target.value)"
                                :value="groupText"
                                @focus="groupOptionsShow = true"
                                @blur="groupOptionsShow = false" 
                                class="bg-transparent text-[#fff] text-[14px] text-center outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[3px] w-full disabled:opacity-40" 
                                type="text"
                                placeholder="група"
                            />
                        </div>
                        <div v-if="groupOptionsShow" class="z-[150] overflow-auto absolute max-h-[100px] w-[230px] border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20">
                            <div v-for="groupOption in groupVisibleOptions" @mousedown="setGroup(groupOption)" class="w-full px-1 bg-[#8C8C8C] hover:bg-[#8C8C8C] hover:bg-opacity-80 cursor-pointer text-[#000]">{{groupOption.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="bg-[#474849] bg-opacity-20">
                            <input
                                @change="changeLocationText($event.target.value)"
                                :value="locationText"
                                @focus="locationOptionsShow = true"
                                @blur="locationOptionsShow = false" 
                                class="bg-transparent text-[#fff] text-[14px] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 text-center rounded-[4px] py-1 px-[3px] w-full disabled:opacity-40" 
                                type="text"
                                placeholder="локація"
                            />
                        </div>
                        <div v-if="locationOptionsShow" class="z-[150] overflow-auto absolute max-h-[100px] w-[230px] bg-[#211C1F]">
                            <div v-for="locationOption in locationVisibleOptions" @mousedown="setLocation(locationOption)" class="w-full px-1 bg-[#8C8C8C] hover:bg-[#8C8C8C] hover:bg-opacity-80 cursor-pointer text-[#000]">{{locationOption.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full flex flex-col items-center relative">
                        <div class="bg-[#474849] bg-opacity-20 w-full">
                            <textarea
                                v-model="description"
                                class="bg-transparent text-[#fff] text-[14px] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] p-1 w-full disabled:opacity-40" 
                                type="text"
                                rows="6"
                                placeholder="Коментар до тренування"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <button @click="sendData" class="bg-[#48E5C2] text-[#2B2A29] text-center w-full rounded-md py-1 hover:bg-opacity-90">Зберегти</button>
        </div>
        <div class="mt-2">
            <button @click="$emit('close')" class="bg-[#FECC00] text-[#2B2A29] text-center w-full rounded-md py-1 hover:bg-opacity-90">Закрити</button>
        </div>

        
    </div>
</template>

<script>
    import { ref, computed, reactive, onBeforeMount } from 'vue'
    import { getAllLocations, getAllGroups, addNewTraining } from '@/services/apiRequests'
    import MultiSelect from './MultiSelect.vue'

    export default {
        name: 'new-training-form',

        props: {
            date: {
                type: Date,
                default: '2022-12-12',
                required: true
            }
        },

        setup(props, { emit }) {
            const settedDate = new Date(props.date)
            const settedYear = settedDate.getFullYear() >= 10 ? settedDate.getFullYear() : `0${settedDate.getFullYear()}`
            const settedMonth = settedDate.getMonth() + 1 >= 10 ? settedDate.getMonth() + 1 : `0${settedDate.getMonth() + 1}`
            const settedDay = settedDate.getDate() >= 10 ? settedDate.getDate() : `0${settedDate.getDate()}`
            const changeDate = ref(`${settedYear}-${settedMonth}-${settedDay}`)

            const trainingRepeat = ref([])
            const addCurrentDateFlag = ref(false)

            const group = ref(null)
            const groupText = ref('')
            
            const location = ref(null)
            const locationText = ref('')

            const groupOptions = ref([])
            const locationOptions = ref([])

            const loadingSpinnerFlag = ref(false)

            const trainingType = ref('null')

            onBeforeMount(async () => {
                const resGroups = await getAllGroups()
                groupOptions.value = resGroups.data

                const resLocations = await getAllLocations()
                locationOptions.value = resLocations.data
            })

            const groupVisibleOptions = computed(() => {
                return groupOptions.value.filter(option => option.name.toLowerCase().includes(groupText.value.toLowerCase()) || groupText.value === '')
            })

            const locationVisibleOptions = computed(() => {
                return locationOptions.value.filter(option => option.name.toLowerCase().includes(locationText.value.toLowerCase()) || locationText.value === '')
            })

            const changeGroupText = (groupName) => {
                groupText.value = groupName
                group.value = groupVisibleOptions.value[0]
            }

            const changeLocationText = (locationName) => {
                locationText.value = locationName
                location.value = locationVisibleOptions.value[0]
            }

            const setLocation = (locationOption) => {
                console.log('location', location)
                location.value = locationOption
                console.log('location', location.value)
                locationText.value = locationOption.name
                locationOptionsShow.value = false
            }

            const setGroup = (groupOption) => {
                group.value = groupOption;
                groupText.value = groupOption.name;
                groupOptionsShow.value = false;
            }

            const locationOptionsShow = ref(false)
            const groupOptionsShow = ref(false)

            const trainingTime = ref({
                start: '00:00',
                end: '00:00'
            })

            const sendData = async () => {
                trainingType.value = trainingType.value == 'none' ? null : trainingType.value
                if (!addCurrentDateFlag.value) {
                    loadingSpinnerFlag.value = true
                    addNewTraining(
                        group.value.name, 
                        group.value.group_id, 
                        location.value.small_name, 
                        location.value.location_id, 
                        trainingTime.value.start, 
                        trainingTime.value.end, 
                        changeDate.value,
                        trainingType.value
                    ).then(res => {
                        console.log('result adding', res)
                        loadingSpinnerFlag.value = false
                        emit('updateTrainings')
                        emit('close')
                    })
                } else {
                    loadingSpinnerFlag.value = true
                    const days = []
                    trainingRepeat.value.forEach(day => {
                        if(day.selected) {
                            const allCurrentDayInMonth = getDatesByDayOfWeek(settedMonth, settedYear, day.value)
                            days.push(...allCurrentDayInMonth)
                        }
                    })
                    for (let i = 0; i < days.length; i++) {
                        await addNewTraining(
                            group.value.name, 
                            group.value.group_id, 
                            location.value.small_name, 
                            location.value.location_id, 
                            trainingTime.value.start, 
                            trainingTime.value.end, 
                            days[i],
                            trainingType.value
                        )
                    }
                    loadingSpinnerFlag.value = false
                    emit('updateTrainings')
                    emit('close')
                }
            }

            const getDatesByDayOfWeek = (month, year, dayOfWeek) => {
                const dates = [];
                const date = new Date(year, month - 1, 1);
                const daysInMonth = new Date(year, month, 0).getDate();
                
                let firstDayOfWeek = date.getDay();
                if (firstDayOfWeek > dayOfWeek) {
                    firstDayOfWeek = dayOfWeek - firstDayOfWeek + 7;
                } else {
                    firstDayOfWeek = dayOfWeek - firstDayOfWeek;
                }
                
                date.setDate(firstDayOfWeek + 1);
                dates.push(new Date(date));
                
                while (date.getDate() + 7 <= daysInMonth) {
                    date.setDate(date.getDate() + 7);
                    dates.push(new Date(date));
                }
                
                console.log('dates', dates)
                return dates;
            }

            return {
                changeDate,
                trainingTime,
                group,
                location,
                groupOptions,
                locationOptions,
                locationOptionsShow,
                groupOptionsShow,
                groupVisibleOptions,
                locationVisibleOptions,
                groupText,
                locationText,
                changeGroupText,
                changeLocationText,
                setLocation,
                setGroup,
                sendData,
                trainingRepeat,
                addCurrentDateFlag,
                loadingSpinnerFlag,
                trainingType
            }
        },

        components: {
            MultiSelect
        }
    }
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Fixel';
        src: url('../../../assets/fonts/FixelDisplay/FixelDisplay-Regular.ttf');
    }

    .window {
        font-family: 'Fixel';
    }
</style>
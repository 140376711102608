<template>
    <div class="search-block w-[90%] mx-auto">
        <div class="flex justify-between mt-2">
            <div class="search-bar flex items-center border-[#FFF] border-[1px] bg-[#FFF] bg-opacity-30 px-[10px] rounded-[6px]">
                <img src="@/assets/icons/magnify_icon.svg" class="w-3 h-3">
                <input v-model="searchQuery" class="text-[#FFF] text-[14px] bg-transparent rounded-md px-2 py-[3.5px] placeholder:text-[#FFF] outline-none w-[250px] " placeholder="Пошук..." type="text">
                <!-- <button class="bg-slate-500 hover:bg-slate-600 text-slate-200 px-2 py-1 rounded-md ml-2">Фільтр</button> -->
            </div>
            <div class="filter">
                <button class="bg-[#FECC00] hover:bg-[#a77f2b] w-[228px] text-[#2B2A29] px-4 py-[6px] text-[14px] rounded-md" @click="$emit('addGroup')">Додати</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue'

    export default {
        name: 'menu-bar',

        setup(props, { emit }) {
            const searchQuery = ref('')

            watch(searchQuery, (newValue) => {
                emit('search', newValue)
            })

            return {
                searchQuery
            }
        }
    }
</script>

<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#211C1F] w-[400px] h-[305px] rounded-[10px] pt-[10px] mt-[15px] border-[1px] border-[#FFF] border-opacity-10">
        <div class="bg-[#FECC00] text-[13px] font-bold text-center rounded-[4px] mx-[14px] py-[2px]">
            ФІНАНСИ
        </div>
        <div class="mt-[10px]">
            <div class="flex flex-col items-center">
                <div class="text-[#BD252C] text-[20px] font-bold block-title">
                    <span class="font-['Avenir Cyrillic2']">{{ balanceState.toFixed(2) }}</span><span class="font-sans">₴</span>
                </div>
                <div class="text-gray-300 text-[8px] font-light">
                    (поточний баланс)
                </div>
            </div>
            <div class="border-[#8C8C8C] bg-[#474849] bg-opacity-20 mt-[10px] border-[1px] w-[40%] mx-auto items-center flex justify-center relative rounded-[4px] px-[4px]">
                <select :value="currentRoleId" @change="e => changeRole(e.target.value)" class="bg-transparent text-[#fff] text-[12px] outline-none text-center placeholder-gray-500 py-[3px] w-full disabled:opacity-40">
                    <option v-for="role in roles" class="bg-[#1D1D1D]" :value="role.role_id">{{ role.role_name }}</option>
                </select>
            </div>
        </div>

        <outlay-accounting 
            :studentOutlays="allOutlays"
            @newYear="newYear => outlaysYear = newYear"
        />
        
        <income-accounting 
            :studentIncomes="allIncomes"
            :balanceId="balanceId"
        />
    </div>
</template>

<script>
    import { ref, onMounted, onBeforeMount, watch } from 'vue'
    import { getBalanceState, getAllIncomes, getAllOutlays, getAllUserRoles } from '@/services/apiRequests'
    import OutlayAccounting from '../ui/OutlayAccounting.vue'
    import IncomeAccounting from '../ui/IncomeAccounting.vue'
    export default {
        name: 'new-finance-card',
        props: {
            balanceId: {
                type: String,
                default: ''
            },
            roleId: {
                type: String,
                default: ''
            }
        },

        setup(props, { emit }) {
            const balanceState = ref(0)
            const allIncomes = ref([])
            const allOutlays = ref([])
            const outlaysYear = ref(new Date().getFullYear())

            const currentRoleId = ref(props.roleId)
            const roles = ref([])

            const changeRole = async (roleId) => {
                console.log('roleId', roleId)
                const newRole = roles.value.find(role => role.role_id === roleId)
                currentRoleId.value = newRole.role_id
                console.log('currentRoleId', currentRoleId)
                emit('changeRole', newRole)
            }

            const updateRolesList = async () => {
                const rolesRes = await getAllUserRoles()
                roles.value = rolesRes?.map(role => {
                    return {
                        ...role,
                        status: 'show'
                    }
                })
            }
        
            onMounted(async () => {
                allIncomes.value = (await getAllIncomes(props.balanceId)).virtual_incomes
                allOutlays.value = (await getAllOutlays(props.balanceId, outlaysYear.value)).virtual_outlay

                const balanceResponse = await getBalanceState(props.balanceId)
                console.log(props.balanceId)
                console.log('currentRoleId', currentRoleId)
                balanceState.value = balanceResponse

                await updateRolesList()
            })

            watch(outlaysYear, async () => {
                allOutlays.value = (await getAllOutlays(props.balanceId, outlaysYear.value)).virtual_outlay
            })

            return {
                balanceState,
                allIncomes,
                allOutlays,
                outlaysYear,
                roles,
                currentRoleId,
                changeRole
            }
        },

        components: {
            OutlayAccounting,
            IncomeAccounting
        },
    }
</script>


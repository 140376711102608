<template>
    <div class="border-[1px] border-[#FFF] border-opacity-10 w-full px-[21px] py-[17px] rounded-[10px] mt-[27px]">
        <div class="uppercase bg-[#FECC00] text-[13px] py-[4px] font-bold text-center rounded-[4px]">
            конструктор ролей
        </div>

        <div v-for="role in roles" class="flex items-center justify-between py-[12px]">
            <div class="text-white w-[30%]">
                <div v-if="role.status == 'show'">{{ role.role_name }}</div>
                <input v-if="role.status == 'edit'" v-model="role.role_name" type="text" class="bg-transparent text-[#FECC00] placeholder:text-white placeholder:opacity-40 relative outline-none" placeholder="Нова підкатегорія...">
            </div>
            <div class="text-[#48E5C2] flex w-[30%] justify-center">
                <div v-if="role.status == 'show'" class="w-[35px] text-end">{{ role.writeoff_summ }}</div>
                <input v-if="role.status == 'edit'" v-model="role.writeoff_summ" type="number" class="no-spinners text-end bg-transparent text-[#48E5C2] placeholder:text-[#48E5C2] w-[35px] placeholder:opacity-40 relative outline-none" placeholder="0.00">
                <div class="ml-[6px]">грн.</div>
            </div>
            <div class="flex w-[30%] justify-end">
                <img @click="role.status = 'edit'" v-if="role.status == 'show'" src="@/assets/icons/pen_icon.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                <img @click="updateRole(role)" v-if="role.status == 'edit'" src="@/assets/icons/green_check_point.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                <img @click="deleteRole(role.role_id)" src="@/assets/icons/trash_icon.svg" class="cursor-pointer mr-[10px]">
            </div>
        </div>


        <div class="flex items-center justify-between py-[12px] w-full">
            <div class="text-white w-[30%]">
                <input v-model="newRole.role_name" type="text" class="bg-transparent text-[#FECC00] placeholder:text-white placeholder:opacity-40 relative outline-none" placeholder="Нова підкатегорія...">
            </div>
            <div class="text-[#48E5C2] w-[30%] flex justify-center">
                <input v-model="newRole.writeoff_summ" type="number" class="no-spinners text-end bg-transparent text-[#48E5C2] placeholder:text-[#48E5C2] w-[35px] placeholder:opacity-40 relative outline-none" placeholder="0.00">
                <div class="ml-[6px]">грн.</div>
            </div>
            <div class="flex w-[30%] justify-end">
                <img @click="addRole" src="@/assets/icons/green_check_point.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                <img src="@/assets/icons/trash_icon.svg" class="cursor-pointer mr-[10px]">
            </div>
        </div>
        
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { addUserRoleRequest, deleteUserRoleRequest, updateUserRoleRequest, getAllUserRoles } from '@/services/apiRequests'

    export default {
        name: "WriteoffSettings",

        setup() {
            const roles = ref([])


            const newRole = ref({
                role_name: '',
                writeoff_summ: null
            })

            const updateRolesList = async () => {
                const rolesRes = await getAllUserRoles()
                roles.value = rolesRes?.map(role => {
                    return {
                        role_id: role.role_id,
                        role_name: role.role_name,
                        writeoff_summ: role.writeoff_summ,
                        status: 'show'
                    }
                })
            }

            onMounted(async () => {
                await updateRolesList()
            })

            const addRole = async () => {
                await addUserRoleRequest(newRole.value)
                await updateRolesList()
                newRole.value = {
                    role_name: '',
                    writeoff_summ: null
                }
            }

            const deleteRole = async (roleId) => {
                await deleteUserRoleRequest(roleId)
                await updateRolesList()
            }

            const updateRole = async (role) => {
                await updateUserRoleRequest(role.role_id, role)
                await updateRolesList()
            }
         
            return {
                roles,
                newRole,
                addRole,
                deleteRole,
                updateRole
            }
        }
    }
</script>

<style lang="scss" scoped>
    .no-spinners::-webkit-inner-spin-button,
    .no-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
</style>
<template>
    <delete-student-form 
        v-if="deleteStudentFormFlag"
        :student="deleteStudentFormData"
        @close="deleteStudentFormFlag = false"
        @acceptDeleteStudent="deleteStudentVM($event)"
        @updateStudents="updateUsers" 
    />
    <new-student-form :show = "showNewStudentForm" @close="showNewStudentForm = false" @updateStudents="updateUsers"/>
    <student-card  
        v-if="showStudentCardFlag" 
        :student="currentStudent" 
        @close="showStudentCardFlag = false"
        @updateUsers="updateUsers" 
    />
    <div v-if="currentStatusFlag" @click="currentStatusFlag = false" class="absolute w-full bg-transparent h-full overflow-hidden top-0 left-0 z-0"></div>
    <div class="overflow-y-auto mt-[70px]">
        <menu-bar v-model="searchQuery" v-model:page="currentPage" :maxPage="maxPage" @startFilter="filterAllStudents" @openFilters="" @addStudent="showNewStudentForm = true" />

        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[60px]">
            <div class="text-white">
                <span class="text-[28px] font-semibold">{{ allActiveStudentsCount }}</span> активних
            </div>
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ allDebtStudentsCount }}</span> боржників
            </div>
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ allArchiveStudentsCount }}</span> архівних
            </div>
        </div>

        <keep-alive>
        <div class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
            <table class="w-full">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <th class="px-3 py-2 w-[5%] text-center font-medium text-sm"></th>
                        <th class="px-3 py-2 w-[15%] text-start font-medium text-sm">Ім'я</th>
                        <th class="px-3 py-2 w-[10%] text-start font-medium text-sm">Група</th>
                        <th class="px-3 py-2 w-[10%] text-start font-medium text-sm">Телефон</th>
                        <th @click="balanceNewFilter" class="w-[107px] px-3 py-2 cursor-pointer select-none text-end font-medium flex justify-end align-middle items-center text-sm">
                            <div class="text-end">
                                Баланс
                            </div>
                            <div class="flex  flex-col justify-between items-center align-middle">
                                <div v-if="balanceVector == 'desc'" class="text-[#4F46E5] text-center mt-[4px]">
                                    <img src="@/assets/icons/angle-small-up.svg" class="w-3">
                                </div>
                                <div v-if="balanceVector == 'asc'" class="text-white text-center mt-[4px]">
                                    <img src="@/assets/icons/angle-small-down.svg" class="w-3">
                                </div>
                                <div v-else></div>
                            </div>
                        </th>
                        <th class="w-[5%]" v-if="screenWidth > 1450"></th>
                        <th class="px-3 py-2 text-end w-[204px] relative font-medium text-sm">
                            <div @click="currentStatusFlag = !currentStatusFlag" v-if="!showStudentCardFlag && !showNewStudentForm"  class="cursor-pointer w-[178px] z-[-40] pr-[26px]">Статус</div>
                            <div v-if="currentStatusFlag" class="absolute top-[40px] right-[-70px] bg-[#211C1F] border-b-[1px] border-x-[1px] border-[#374957] rounded-b-[10px] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.4)] px-2 py-3 flex">
                                <div class="flex justify-center items-center mx-2">
                                    <input @change="changeStatus('active')" :checked="currentStatuses.includes('active')" type="checkbox" class="mr-[2px] cursor-pointer">
                                    <div class="bg-[#48E5C2] mx-2 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                                        <div class="block">
                                            Активний
                                        </div>
                                    </div>  
                                </div>
                                <div class="flex justify-center items-center mx-2">
                                    <input @change="changeStatus('archive')" :checked="currentStatuses.includes('archive')" type="checkbox" class="mr-[2px] cursor-pointer">
                                    <div class="bg-[#D9D9D9] mx-2 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-[#78797C]">
                                        Архів
                                    </div> 
                                </div>
                            </div>
                        </th>
                        <th class="px-3 w-[35%] py-2 text-start font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody v-if="allStudents.length > 0">
                    <new-student-row
                        v-for="student in allStudents" 
                        @openStudentCard="showStudentCard(student)"
                        @deleteStudent="deleteStudentFormData = student; deleteStudentFormFlag = true"
                        @removeStudentFromArchive="deleteStudentFromArchive(student)"
                        :key="student.student_id" 
                        :student="student"
                    />
                </tbody>
            </table>
        </div>
        </keep-alive>
        <div v-if="loading" class="z-50 fixed w-full h-full">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#BD252C] border-t-transparent absolute top-[50%] left-[50%] ml-[-160px] mt-[-90px]"></div>
        </div>
        <div class="w-full mt-[14px] flex items-center justify-center" v-else-if="allStudents.length == 0">
            <p class="text-[#A3A3C2] text-[16px]">Учнів не знайдено</p>
        </div>  
    </div>
</template>

<script>
    import { onMounted, ref, computed, watch } from 'vue'
    import { getAllStudents, deleteStudent, getBalanceState, getStudentsCount, getActiveStudentsCount, getDebtStudentsCount, getArchiveStudentsCount, addStudentToArchive, removeStudentFromArchive } from '@/services/apiRequests'
    import MenuBar from './components/MenuBar.vue'
    import StudentRow from './components/StudentRow.vue'
    import NewStudentForm from './components/NewStudentForm.vue'
    import StudentCard from './components/StudentCard/StudentCard.vue'
    import NewStudentRow from './components/NewStudentRow.vue'
    import DeleteStudentForm from './components/DeleteStudentForm.vue'

    export default {
        setup() {
            const allStudents = ref([])
            const showNewStudentForm = ref(false)
            const showStudentCardFlag = ref(false)
            const currentStudent = ref({})
            const deleteStudentFormFlag = ref(false)
            const deleteStudentFormData = ref({})
            const cloneAllStudents = ref([])
            const searchQuery = ref('')
            const currentPage = ref(1)
            const maxPage = ref(1)
            const loading = ref(false)
            const screenWidth = ref(window.innerWidth)

            const allDebtStudentsCount = ref(0)
            const allActiveStudentsCount = ref(0)
            const allArchiveStudentsCount = ref(0)

            const balanceVector = ref(null)
            const balanceNewFilter = () => {
                balanceVector.value = balanceVector.value === null ? 'asc' : balanceVector.value === 'asc' ? 'desc' : null
                updateUsers()
                // filterAllStudents()
            }

            watch(searchQuery, () => {
                updateUsers()
            })

            const currentStatuses = ref(['active'])
            const changeStatus = async (status) => {
                if (currentStatuses.value.includes(status)) {
                    currentStatuses.value = currentStatuses.value.filter((item) => item !== status)
                } else {
                    currentStatuses.value.push(status)
                }
                await updateUsers()
                // filterAllStudents()
            }
            const currentStatusFlag = ref(false)

            // const filterAllStudents = () => {
            //     let visibleResult = []
                
            //     if (currentStatuses.value.length === 0) {
            //         visibleResult = []
            //     } else {
            //         currentStatuses.value.forEach((status) => {
            //             if (status === 'active') visibleResult.push(...cloneAllStudents.value.filter((student) => student.status.includes('active') && student.balance >= 0))
            //             if (status === 'archive') visibleResult.push(...cloneAllStudents.value.filter((student) => student.status.includes('archive')))
            //             if (status === 'debtor') visibleResult.push(...cloneAllStudents.value.filter((student) => student.balance < 0))
            //         })
            //     }
               
            //     if (balanceVector.value === 'none') visibleResult = visibleResult
            //     if (balanceVector.value === 'up') visibleResult = visibleResult.sort((a, b) => b.balance - a.balance)
            //     if (balanceVector.value === 'down') visibleResult = visibleResult.sort((a, b) => a.balance - b.balance)

            //     searchQuery.value = searchQuery.value.toLowerCase()
            //     visibleResult = visibleResult.filter((student) => {
            //         return student.name.toLowerCase().includes(searchQuery.value) || student.surname.toLowerCase().includes(searchQuery.value) || student.phone.toLowerCase().includes(searchQuery.value) || student.group_name.toLowerCase().includes(searchQuery.value)
            //     })

            //     console.log('visible result', visibleResult)
            //     allStudents.value = visibleResult
            // }

            const updateUsers = async () => {
                loading.value = true
                allStudents.value = []
                const res = await getAllStudents(20, (currentPage.value - 1) * 20, balanceVector.value, searchQuery.value, null, null, currentStatuses.value)
                allStudents.value = res.data

                allStudents.value.map(async (student) => {
                    student.balance = await getBalanceState(student.balance_id)
                    student.balance = student.balance.toFixed(2)
                    return student
                })
                loading.value = false
            }

            const deleteStudentVM = async (student) => {
                if (student.status.includes('active')) {
                    const res = await addStudentToArchive(student.student_id)
                    updateUsers()
                    deleteStudentFormFlag.value = false
                    await statsUpdate()
                } else if (student.status.includes('archive')) {
                    const res = await deleteStudent(student.student_id)
                    if (res.status === 200) {
                        updateUsers()
                        deleteStudentFormFlag.value = false
                    }
                    await statsUpdate()
                }
            }

            const deleteStudentFromArchive = async (student) => {
                const res = await removeStudentFromArchive(student.student_id)
                updateUsers()
                deleteStudentFormFlag.value = false
                await statsUpdate()
            }

            const statsUpdate = async () => {
                const allDebtStudentsCountRes = await getDebtStudentsCount()
                console.log('allDebtStudentsCountRes --------- ', allDebtStudentsCountRes)
                allDebtStudentsCount.value = allDebtStudentsCountRes
                const allActiveStudentsCountRes = await getActiveStudentsCount()
                allActiveStudentsCount.value = allActiveStudentsCountRes
                const allArchiveStudentsCountRes = await getArchiveStudentsCount()
                allArchiveStudentsCount.value = allArchiveStudentsCountRes
            }

            onMounted(async () => {
                loading.value = true
                await updateUsers()
                const allUsersCount = await getStudentsCount()
                console.log('allUsersCount --------- ', allUsersCount)
                maxPage.value = Math.ceil(allUsersCount / 20)
                cloneAllStudents.value = [...allStudents.value]
                loading.value = false

                await statsUpdate()

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            const showStudentCard = (student) => {
                console.log('student ---------- ', student)
                currentStudent.value = student
                showStudentCardFlag.value = true
            }

            const formatDate = (date) => {
                return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
            }

            watch(currentPage, async () => {
                loading.value = true
                if (maxPage.value <= currentPage.value) currentPage.value = maxPage.value
                await updateUsers()
                loading.value = false
            })

            return {
                allStudents,
                showNewStudentForm,
                formatDate,
                updateUsers,
                showStudentCard,
                showStudentCardFlag,
                currentStudent,
                deleteStudentFormFlag,
                deleteStudentFormData,
                deleteStudentVM,
                balanceVector,
                balanceNewFilter,
                currentStatuses,
                changeStatus,
                currentStatusFlag,
                searchQuery,
                currentPage,
                maxPage,
                loading,
                screenWidth,
                allDebtStudentsCount,
                allActiveStudentsCount,
                allArchiveStudentsCount,
                deleteStudentFromArchive
            }
        },

        components: {
            MenuBar,
            StudentRow,
            NewStudentForm,
            StudentCard,
            NewStudentRow,
            DeleteStudentForm
        }
    }
</script>

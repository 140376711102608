<template>
    <div @click="$emit('close')" style="background-color: rgba(53, 53, 53, 0.10)" class="backdrop-blur-[7px] fixed w-full z-40 h-full -mt-2"></div>
    <div v-if="loadingSpinnerFlag" class="z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#BD252C] border-t-transparent absolute top-[50%] left-[50%] ml-[-180px] mt-[-50px]"></div>
    </div>
    <ImageViewer v-if="viewImageFlag" @close="viewImageFlag = false" @delete="deleteImage" :imageUrl="viewImageURL" altText="dddd" class="z-50"/>
    <div v-if="!viewImageFlag && !loadingSpinnerFlag" style="box-shadow: 4px 4px 4px 0px #00000040;" class="border-[1px] border-[#FFF] border-opacity-10 bg-[#211C1F] rounded-[10px] z-50 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px]">
        <div class="px-[10px] py-[10px] w-[350px]">
            <div class="flex justify-center"><img src="../../../../assets/logo.svg" class="h-[70px]"></div>
            <div class="font-semibold text-center text-[16px] text-white mb-[25px] mt-[10px]">
                Прийняти платіж
            </div>
            <div class="w-full">    
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    занести на сервісний рахунок
                </div>
                <select v-model="serviceBalanceLabel" class="mt-[3px] w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none" name="to_balance">
                    <option value="Cashbox" class="bg-[#211C1F] text-[#fff]">Каса ({{ admin.admin_name + ' ' + admin.admin_surname }})</option>
                    <option value="Bank" class="bg-[#211C1F] text-[#fff]">Банк</option>
                </select>
            </div>
            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    сума транзакції
                </div>
                <div class="flex justify-between mt-[3px]">
                    <input v-model="amount" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none" type="number" name="amount">
                </div>
            </div>
            <div class="w-full mt-[20px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    коментар до транзакції
                </div>
                <div class="flex justify-between mt-[3px]">
                    <textarea v-model="transactionComment" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none" type="text" name="comment"></textarea>
                </div>
            </div>
            <div class="w-full mt-[20px]">
                <div class="flex items-center justify-between text-[#fff]">
                    <div>
                        Додаткові файли:
                    </div>
                    <div class="flex justify-end">
                        <img v-for="image in allImages" @click="viewPhoto(image)" src="@/assets/icons/picture_icon.svg" class="mx-[2px] cursor-pointer">
                        <img src="@/assets/icons/upload_icon.svg" class="ml-2 cursor-pointer" @click="uploadPhoto">
                    </div>
                </div>
                <input ref="imagesInput" @change="selectPhoto($event)" class="w-full bg-gray-100 py-[5px] pl-[5px] outline-none hidden" type="file" name="additional_files">
            </div>
            <button @click="sendTransaction" class="w-full rounded-[6px] bg-[#48E5C2] text-[#2B2A29] py-[7px] mt-[20px]">
                Прийняти
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getAllVirtualBalances, addRealIncomeRequest, addVirtualIncomeRequest, getServiceBalanceObjectByLabel, getAdmin } from '@/services/apiRequests'
    import ImageViewer from '../ui/ImageViewer.vue'

    async function readFileObjectAsDataURL (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (event) => {
                resolve(event.target.result)
            }
            reader.onerror = (error) => {
                reject(error)
            }
            reader.readAsDataURL(file)
        })
    }

    export default {
        name: 'get-payment-popup',

        emits: ['close', 'updateTransactions'],

        setup(props, { emit }) {
            const allVirtualBalances = ref([])
            const showVirtualBalances = ref([])
            const studentBalanceName = ref('')
            const studentBalanceId = ref('')
            const showBalancesFlag = ref(false)
            const imagesInput = ref(null)
            const allImages = ref([])
            const admin = ref({})

            const viewImageObject = ref({})
            const viewImageURL = ref('')
            const viewImageFlag = ref(false)

            const amount = ref(null)
            const dateTx = ref(null)
            const serviceBalanceLabel = ref('Cashbox')
            const serviceBalanceObject = ref({})
            const studentBalanceState = ref(undefined)

            const loadingSpinnerFlag = ref(true)

            const transactionComment = ref('')

            watch(studentBalanceName, (value) => {
                if (value.length) {
                    showVirtualBalances.value = allVirtualBalances.value.filter((balance) => {
                        return balance.label.toLowerCase().includes(value.toLowerCase())
                    })
                } else {
                    showVirtualBalances.value = allVirtualBalances.value
                }
            })

            const uploadPhoto = () => {
                imagesInput.value.click()
            }

            const selectPhoto = (event) => {
                allImages.value.push(event.target.files[0])
            }

            const viewPhoto = async (image) => {
                viewImageURL.value = await readFileObjectAsDataURL(image)
                viewImageObject.value = image
                viewImageFlag.value = true
            }

            const deleteImage = () => {
                allImages.value = allImages.value.filter((img) => {
                    return img !== viewImageObject.value
                })
                viewImageFlag.value = false
            }

            watch(studentBalanceId, (value) => {
                showBalancesFlag.value = false
            })

            const chooseBalance = (balance) => {
                studentBalanceId.value = balance.balance_id
                studentBalanceName.value = balance.label
                studentBalanceState.value = balance.state
            }

            const sendTransaction = async () => {
                loadingSpinnerFlag.value = true
                if (serviceBalanceLabel.value == 'Cashbox') {
                    console.log('cashbox ------- ', admin.value)
                    const resRealTx = await addRealIncomeRequest(admin.value.cash_balance_id, amount.value, transactionComment.value, '', allImages.value)
                } else {
                    const cashboxBalanceObject = (await getServiceBalanceObjectByLabel(serviceBalanceLabel.value)).balance
                    const resRealTx = await addRealIncomeRequest(cashboxBalanceObject.balance_id, amount.value, transactionComment.value, '', allImages.value)
                }
                loadingSpinnerFlag.value = false
                emit('close')
                emit('updateTransactions')
            } 

            onMounted(async () => {
                const response = await getAllVirtualBalances()
                allVirtualBalances.value = response.balances
                showVirtualBalances.value = response.balances

                admin.value = (await getAdmin()).data
                loadingSpinnerFlag.value = false
            })

            return {
                allVirtualBalances,
                studentBalanceName,
                studentBalanceId,
                showVirtualBalances,
                chooseBalance,
                showBalancesFlag,
                imagesInput,
                allImages,
                uploadPhoto,
                selectPhoto,
                viewPhoto,
                viewImageURL,
                viewImageFlag,
                deleteImage,
                amount,
                dateTx,
                serviceBalanceObject,
                serviceBalanceLabel,
                sendTransaction,
                loadingSpinnerFlag,
                admin,
                studentBalanceState,
                transactionComment
            }
        },

        components: {
            ImageViewer
        }
    }
</script>

<style lang="scss" scoped>

.scrollbar::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
}

.scrollbar::-webkit-scrollbar-track {
    background: #ededed;        /* цвет зоны отслеживания */
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #a6a4a4;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


</style>
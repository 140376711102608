<template>
<div @click="$emit('close')" style="background-color: rgba(171, 211, 236, .25)" class="backdrop-blur-[2px] fixed w-full h-[130%] top-[-70px] mt-[-10px]"></div>
    <div class="bg-[#FECC00] ml-[60px] mb-[10px] w-[260px] px-4 py-3 rounded-[10px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div class="text-[13px] bg-[#D9D9D9] text-[#000] px-[6px] py-[5px] rounded-[10px] mb-[14px]">
            <input v-model="locationMV.name" class="bg-transparent text-[13px] outline-none border-none" placeholder="Назва локації" type="text">
        </div>
        <div class="bg-[#E5E5E5] px-[6px] py-[5px] rounded-[10px] mb-[14px]">
            <textarea v-model="locationMV.address" class="bg-transparent text-[13px] outline-none border-none" rows="2" placeholder="Адреса"></textarea>
        </div>
        <div @click="sendToMap" class="bg-[#211C1F] cursor-pointer text-white px-[6px] py-[5px] rounded-[10px] mb-[14px] flex justify-between items-center">
            <div>
                Перейти на карту
            </div>
            <div>
                <img class="w-[15px]" src="@/assets/icons/link_icon.svg">
            </div>
        </div>
        <div class="bg-[#E5E5E5] px-[6px] py-[5px] rounded-[10px] mb-[14px]">
            <textarea v-model="locationMV.description" class="w-full bg-transparent outline-none border-none resize-none" rows="2" placeholder="Додатковий опис об'єкта"></textarea>
        </div>
        <div>
            <div class="flex justify-between mb-[4px]">
                <div>
                    Фото об'єкта
                </div>
                <div class="flex">
                    <img @click="selectLocationPhoto" class="mr-[7px] w-[15px] cursor-pointer" src="@/assets/icons/upload_icon.svg">
                    <input class="hidden" type="file">
                    <img @click="deleteLocationPhoto" class="w-[15px] cursor-pointer" src="@/assets/icons/trash_icon.svg">
                </div>
            </div>
            <div class="w-full h-[132px] border-[2px] border-[#211C1F] rounded-[10px]">
                <img v-if="locationPhoto" :src="locationPhotoDataUrl" class="w-full h-full object-cover rounded-[10px]">
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'

    export default {
        props: {
            location: {
                type: Object,
                default: () => ({})
            }
        },
        
        setup(props) {
            const locationMV = ref(props.location)
            const locationPhoto = ref(props.location.photo)
            const locationPhotoDataUrl = ref(process.env.VUE_APP_API_URL + 'static/' + props.location.photo)

            const sendToMap = () => {
                const mapQuery = locationMV.address.split(' ').join('%20')
                window.open(`https://www.openstreetmap.org/search?query=${mapQuery}`, '_blank')
            }

            const selectLocationPhoto = () => {}

            const deleteLocationPhoto = () => {}

            return {
                locationPhoto,
                locationPhotoDataUrl,
                sendToMap,
                selectLocationPhoto,
                deleteLocationPhoto,
                locationMV
            }
        }
    }
</script>

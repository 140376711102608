<template>
    <div class="bg-[#FECC00] w-[160px] h-[100px] rounded-[10px] mx-2 cursor-pointer">

        <div class="w-full bg-[#211C1F] text-white text-center font-semibold mt-[10px]">
            {{ group.name }}
        </div>

        <div class="flex align-middle items-center ml-[9px] mt-[5px]">
            <div class="mr-[7px]">
                <img v-if="photo == null" width="28" src="@/assets/icons/human_icon.svg">
                <div v-else class="w-[28px] h-[28px] rounded-full overflow-hidden border-[#211C1F] border-[1px]">
                    <img :src="photo" @error="$event.target.src = require('@/assets/student-photo-placeholder-tpbg.png')">
                </div>
            </div>
            <div class="text-[#211C1F] font-bold border-b-[1px] border-[#211C1F21] border-opacity-10">
                <span v-if="trainerName.length > 0">{{ trainerName }}</span>
                <span v-else>Не задано</span>
            </div>
            
        </div>

        <div class="ml-[45px] text-[#211C1F]">
            <div>
                <span class="font-bold">{{ 0 }}</span> <span class="font-medium">гравців</span>
            </div>
        </div>
 
    </div>
</template>

<script>
    import { getTrainerById } from '@/services/apiRequests'
    import { ref, onBeforeMount } from 'vue'

    export default {
        name: 'group-card',

        props: {
            group: {
                type: Object,
                required: true
            }
        },

        setup(props) {
            const photo = ref(null)
            const trainerName = ref('')

            onBeforeMount(async () => {
                if (props.group.trainer_id === null) return

                const res = await getTrainerById(props.group.trainer_id)
                const trainer = res.data 
                photo.value = process.env.VUE_APP_API_URL + 'static/' + trainer.photo
                trainerName.value = trainer.surname + ' ' + trainer.name[0] + '.'
            })

            return {
                photo,
                trainerName
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>
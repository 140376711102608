<template>
    <div class="border-[1px] border-[#FFF] border-opacity-10 w-full px-[21px] py-[17px] rounded-[10px] mt-[27px]">
        <div class="uppercase bg-[#FECC00] py-[4px] text-[13px] font-bold text-center rounded-[4px]">
            Загальна інфоРмація
        </div>

        <div class="flex w-full justify-between">
            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Назва клубу/школи
                    </div>
                    <div class="mt-[3px]">
                        <input 
                            :value="clubNameUkr"
                            @change="$emit('update:clubNameUkr', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
                <div class="w-[100%] mt-[23px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Юр. адреса
                    </div>
                    <div class="mt-[3px]">
                        <textarea
                            :value="legalAddress"
                            @change="$emit('update:legalAddress', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        ></textarea>
                    </div>
                </div>
            </div>

            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Назва клубу/школи (EN)
                    </div>
                    <div class="mt-[3px]">
                        <input 
                            :value="clubNameEng"
                            @change="$emit('update:clubNameEng', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        >
                    </div>
                </div>
                <div class="w-[100%] mt-[23px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Поштова адреса
                    </div>
                    <div class="mt-[3px]">
                        <textarea
                            :value="postAddress"
                            @change="$emit('update:postAddress', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        ></textarea>
                    </div>
                </div>
            </div>

            <div class="mt-[31px] w-[27%]">
                <div class="w-[100%]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Дата заснування
                    </div>
                    <div class="mt-[3px]">
                        <input 
                            :value="dateOfFoundationText"
                            @change="$emit('update:dateOfFoundationText', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="date" 
                            name="name"
                        >
                    </div>
                </div>
                <div class="w-[100%] mt-[23px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        Посилання на карту Google
                    </div>
                    <div class="mt-[3px]">
                        <textarea
                            :value="linkToMap"
                            @change="$emit('update:linkToMap', $event.target.value)"
                            class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                            type="text" 
                            name="name"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'

    export default {
        name: 'GeneralInformation',

        props: {
            clubNameUkr : {
                type: String,
                default: ''
            },
            clubNameEng : {
                type: String,
                default: ''
            },
            dateOfFoundationText : {
                type: String,
                default: ''
            },
            legalAddress : {
                type: String,
                default: ''
            },
            postAddress : {
                type: String,
                default: ''
            },
            linkToMap: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
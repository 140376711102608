<template>
  <div class="flex">
    <div class="w-[30%] mt-[45px]">
      <div class="flex justify-center items-center">
        <button @click="previousMonth" class="mr-[1px] w-[7px]">
        
          <svg class="w-[7px]" width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
  
  
        </button>
        <span class="text-[9px] w-full font-semibold text-[#FFF]">{{ currentMonth.toUpperCase() }} {{ currentYear }}</span>
        <button @click="nextMonth" class="ml-[1px] w-[7px]">
        
          <svg class="w-[7px]" width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L8.43043 8.17424C9.18986 8.90341 9.18986 10.0966 8.43043 10.8258L2 17" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
  
        
        </button>
      </div>
      <div>
        <div class="flex items-center">
          <div class="w-[10px] h-[7px] bg-[#48E5C2]"></div>
          <div class="text-[9px] ml-[5px] text-[#FFF]">
            тренувався
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-[10px] h-[7px] bg-[#BD252C]"></div>
          <div class="text-[9px] ml-[5px] text-[#FFF]">
            пропустив
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-[10px] h-[7px] bg-[#E6EE5B]"></div>
          <div class="text-[9px] ml-[5px] text-[#FFF]">
            заплановано
          </div>
        </div>
      </div>
    </div>
    <table class="mx-auto mt-[15px]">
      <thead height="5px">
        <tr class="  ">
          <th class="text-[#FFF] text-[9px] align-top" width="10px">Пн</th>
          <th class="text-[#FFF] text-[9px] align-top" width="10px">Вт</th>
          <th class="text-[#FFF] text-[9px] align-top" width="10px">Ср</th>
          <th class="text-[#FFF] text-[9px] align-top" width="10px">Чт</th>
          <th class="text-[#FFF] text-[9px] align-top" width="10px">Пт</th>
          <th class="text-[#FFF] text-[9px] align-top" width="10px">Сб</th>
          <th class="text-[#FFF] text-[9px] align-top" width="10px">Нд</th>
        </tr>
      </thead>
      <tbody class="pt-2" height="100px">
        <tr v-for="week in weeks" height="12px">
          <td 
            v-for="day in week" 
            :key="day.id" 
            class="text-center px-[5px] text-[12px] text-[#FFF]"
            height="14px" width="14px"
          >
            <div 
              class="w-[16px] h-[16px] pt-[1.5px] font-bold rounded-[2px] text-[10px] text-center align-middle mx-auto "
              :class="{
                'bg-transparent' : !day,
                '' : day && !isPlaned(day) && !isAttended(day) && !isMissed(day),
                'bg-[#E6EE5B]' : isPlaned(day) && !isAttended(day) && !isMissed(day),
                'bg-[#339989]' : isAttended(day),
                'bg-[#BD252C]' : isMissed(day),
              }"
            >
              {{ day.monthDay }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { getWeeksInMonth, addingMatchOfTheDayOfTheWeekAndItsNumberInMonth, formatWeeks } from './service/helpers'
  import { getPlanedTrainingsByGroup } from '@/services/apiRequests'

  export default {
    data() {
      return {
        currentMonth: '',
        currentYear: '',
        weeks: [],

        mappedDatePlanedTrainings: []
      }
    },

    props: {
      groupId: {
        type: String,
        required: true
      },
      trainingsAttendedList: {
        type: Array,
        required: true
      },
      trainingsMissedList: {
        type: Array,
        required: true
      }
    },


    created() {
      this.currentDate = new Date()
      this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
      this.currentYear = this.currentDate.getFullYear()
      this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
    },

    mounted() {
      getPlanedTrainingsByGroup(this.groupId)
        .then(res => {
          this.mappedDatePlanedTrainings = res.map(item => {
            const dateObj = new Date(item.date)
            dateObj.setDate(dateObj.getDate())
            return dateObj.toISOString().split('T')[0]
          })
        })
    },

    computed: {
      mappedDateAttendedTrainings() {
        return this.trainingsAttendedList.map(item => {
          const dateObj = new Date(item)
          dateObj.setDate(dateObj.getDate())
          return dateObj.toISOString().split('T')[0]
        })
      },

      mappedDateMissedTrainings() {
        return this.trainingsMissedList.map(item => {
          const dateObj = new Date(item)
          dateObj.setDate(dateObj.getDate())
          return dateObj.toISOString().split('T')[0]
        })
      }
    },

    methods: {
      previousMonth() {
        this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() - 1)
        this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
        this.currentYear = this.currentDate.getFullYear()
        this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
      },
      nextMonth() {
        this.currentDate = new Date(this.currentYear, this.currentDate.getMonth() + 1)
        this.currentMonth = this.currentDate.toLocaleString('uk-UA', { month: 'long' })
        this.currentYear = this.currentDate.getFullYear()
        this.weeks = formatWeeks(addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(getWeeksInMonth(this.currentDate)))
      },
      isPlaned(day) {
        return this.mappedDatePlanedTrainings.includes(day?.date?.toISOString().split('T')[0])
      },
      isAttended(day) {
        return this.mappedDateAttendedTrainings.includes(day?.date?.toISOString().split('T')[0])
      },
      isMissed(day) {
        return this.mappedDateMissedTrainings.includes(day?.date?.toISOString().split('T')[0])
      }
    }
  }
</script>

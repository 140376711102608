<template>
    <div @click="$emit('close')" style="background-color: rgba(53, 53, 53, 0.10)" class="backdrop-blur-[7px] fixed w-full z-40 h-full -mt-2"></div>
    <div v-if="loadingSpinnerFlag" class="z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#BD252C] border-t-transparent absolute top-[50%] left-[50%] ml-[-120px] mt-[-50px]"></div>
    </div>
    <ImageViewer v-if="viewImageFlag" @close="viewImageFlag = false" @delete="deleteImage" :imageUrl="viewImageURL" altText="dddd" class="z-50"/>
    <div v-if="!viewImageFlag && !loadingSpinnerFlag" style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#211C1F] border-[1px] border-[#FFF] border-opacity-10 rounded-[10px] z-50 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px]">
        <div class="px-[10px] py-[10px] w-[350px]">
            <div class="flex justify-center"><img src="../../../../assets/logo.svg" class="h-[70px]"></div>
            <div class="font-semibold text-center text-[16px] text-white mb-[25px] mt-[10px]">
                Додати інвестицію
            </div>
            <div class="w-full mt-[10px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    сума інвестиції
                </div>
                <div class="flex justify-between">
                    <input v-model="amount" class="mt-[3px] w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none" type="number" name="amount">
                </div>
            </div>
            <div class="mt-[10px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    коментар
                </div>
                <div class="flex justify-between">
                    <textarea v-model="comment" name="invest_comment" class="mt-[3px] w-full border-[#ccc] border-[1px] rounded-[6px] text-[#ccc] bg-transparent py-[5px] pl-[5px] outline-none" rows="10"></textarea>
                </div>
            </div>
            <div class="w-full mt-[20px]">
                <div class="flex items-center justify-between">
                    <div class="text-[#fff]">
                        Додаткові файли:
                    </div>
                    <div class="flex justify-end">
                        <img v-for="image in allImages" @click="viewPhoto(image)" src="@/assets/icons/picture_icon.svg" class="mx-[2px] cursor-pointer">
                        <img src="@/assets/icons/upload_icon.svg" class="ml-2 cursor-pointer" @click="uploadPhoto">
                    </div>
                </div>
                <input ref="imagesInput" @change="selectPhoto($event)" class="w-full bg-gray-100 py-[5px] pl-[5px] outline-none hidden" type="file" name="additional_files">
            </div>
            <button @click="sendTransaction" class="w-full rounded-[6px] bg-[#48E5C2] text-[#2B2A29] py-[7px] mt-[20px]">
                Додати
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getAllVirtualBalances, addRealIncomeRequest, getAdmin, addVirtualIncomeRequest, getServiceBalanceObjectByLabel } from '@/services/apiRequests'
    import ImageViewer from '../ui/ImageViewer.vue'

    async function readFileObjectAsDataURL (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (event) => {
                resolve(event.target.result)
            }
            reader.onerror = (error) => {
                reject(error)
            }
            reader.readAsDataURL(file)
        })
    }

    export default {
        name: 'add-invest-popup',

        emits: ['close', 'updateTransactions'],

        setup(props, { emit }) {
            const imagesInput = ref(null)
            const allImages = ref([])
            const viewImageObject = ref({})
            const viewImageURL = ref('')
            const viewImageFlag = ref(false)
            const loadingSpinnerFlag = ref(false)
            const admin = ref({})

            const investBalanceId = ref('')

            const comment = ref('')
            const amount = ref(null)

            const uploadPhoto = () => {
                imagesInput.value.click()
            }

            const selectPhoto = (event) => {
                allImages.value.push(event.target.files[0])
            }

            const viewPhoto = async (image) => {
                viewImageURL.value = await readFileObjectAsDataURL(image)
                viewImageObject.value = image
                viewImageFlag.value = true
            }

            const deleteImage = () => {
                allImages.value = allImages.value.filter((img) => {
                    return img !== viewImageObject.value
                })
                viewImageFlag.value = false
            }

            const sendTransaction = async () => {
                await addRealIncomeRequest(
                    admin.value.invest_balance_id,
                    amount.value,
                    comment.value,
                    null,
                    allImages.value,
                )
                emit('close')
                emit('updateTransactions')
            } 

            onMounted(async () => {
                const responseAdmin = await getAdmin()
                admin.value = responseAdmin.data
            })

            return {
                imagesInput,
                allImages,
                viewImageURL,
                viewImageFlag,
                loadingSpinnerFlag,
                uploadPhoto,
                selectPhoto,
                viewPhoto,
                deleteImage,
                sendTransaction,
                investBalanceId,
                comment,
                amount
            }
        },

        components: {
            ImageViewer
        }
    }
</script>

<style lang="scss" scoped>

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

</style>
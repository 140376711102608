<template>
    <div class="main-info bg-[#211C1F] pt-2 h-[523px] w-[250px] flex flex-col mr-[24px] justify-center items-center rounded-[10px]">
        <div>
            <img 
                @click="uploadPhoto"
                src="@/assets/icons/upload_icon.svg" 
                class="absolute left-[210px] top-[20px] cursor-pointer z-40"
            >
            <input 
                ref="fileInput"
                type="file" 
                class="hidden"
                :value="inputValue"
                @change="$emit('loadPhoto', $event)"
            />
        </div>
        <img :src="photo" @error="photo = require('@/assets/student-photo-placeholder-tpbg.png')" class="w-[148px] mb-[-42px] ">
        <div class="w-full bg-[#FECC00] pb-[6px] font-['Avenir Roman']">
            <div class="text-[#211C1F] text-center text-3xl font-semibold mt-[7px] w-full bg-transparent border-none">
                <span class="font-['Avenir Roman Bold']" v-if="name.length > 0 || surname.length > 0">{{ name + ' ' + surname }}</span>
                <span v-else class="opacity-25">Лєо Мессі</span>
            </div>
            <div class="flex mt-[15px] justify-between h-[32px]">
                <div class="flex justify-between ">
                    <div class="text-center text-[#211C1F] mx-2 h-[30px]">
                        <div class="font-light text-[8px]">група</div>
                        <data-input 
                            :modelValue="group"
                            @update:modelValue="$emit('update:group', $event)"
                            :placeholder="'—'"
                            :blockSize="'30px'"
                            :displayStyles="'text-[13px] w-[30px] font-semibold text-[#211C1F] w-full bg-transparent border-none'"
                            :inputStyles="'text-[#211C1F] text-center text-[13px] p-0 m-0 w-[30px] font-semibold bg-transparent border-none relative top-[-3.5px] outline-none active:outline-none'"
                        />
                    </div>
                    <div class="text-center text-[#211C1F] mx-2">
                        <div class="font-light text-[8px]">вік</div>
                        <div class="text-[13px] font-semibold">11</div>
                    </div>
                    <div class="text-center text-[#211C1F] mx-2">
                        <div class="font-light text-[8px]">амплуа</div>
                        <div class="text-[13px] font-semibold">{{studentAmplua}}</div>
                    </div>
                    <div class="text-center text-[#211C1F] mx-2">
                        <div class="font-light text-[8px]">номер</div>
                        <div class="text-[13px] font-semibold">{{studentNumber}}</div>
                    </div>
                </div>
                <div class="flex items-center mx-2">
                    <img src="@/assets/logo.svg" class="w-[30px]">
                    <div class="text-[#211C1F] mx-1 mb-[2px]">|</div>
                    <img src="@/assets/ua_flag.svg" class="w-[20px] ml-[5px]">
                </div>
            </div>
        </div>
        <div class="w-full px-[27px] mt-3">
            <div class="flex justify-between text-[#177E89] w-full items-center my-[16px] h-[19px]">
                <data-input 
                    :modelValue="surname"
                    @update:modelValue="$emit('update:surname', $event)"
                    :placeholder="'Прізвище'"
                    :blockSize="'100px'"
                    :blockHeight="'19px'"
                    :displayStyles="'font-bold text-[13px] text-[#177E89] w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] font-bold m-0 p-0 relative  bg-transparent border-none outline-none relative top-[-3px] active:outline-none'"
                />
                <div class="opacity-70 text-[11px]">прізвище</div>
            </div>
            <div class="flex justify-between text-[#177E89] w-full items-center my-[16px] h-[19px] ">
                <data-input 
                    :modelValue="name"
                    @update:modelValue="$emit('update:name', $event)"
                    :placeholder="'Ім\'я'"
                    :blockSize="'100px'"
                    :blockHeight="'19px'"
                    :displayStyles="'font-bold text-[13px] text-[#177E89] w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] font-bold m-0 p-0 relative top-[-3px] bg-transparent border-none outline-none active:outline-none'"
                />
                <div class="opacity-70 text-[11px]">ім'я</div>
            </div>
            <div class="flex justify-between text-[#177E89] w-full items-center my-[16px] h-[19px]">
                <data-input 
                    :modelValue="patronymic"
                    @update:modelValue="$emit('update:patronymic', $event)"
                    :placeholder="'По батькові'"
                    :blockSize="'100px'"
                    :blockHeight="'19px'"
                    :displayStyles="'font-bold text-[13px] text-[#177E89] w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] font-bold m-0 p-0 relative top-[-3.5px] bg-transparent border-none outline-none active:outline-none'"
                />
                <div class="opacity-70 text-[11px]">по батькові</div>
            </div>
            <div class="flex justify-between text-[#177E89] w-full items-center my-[16px]">
                <data-input 
                    :modelValue="birthDate"
                    @update:modelValue="$emit('update:birthDate', $event)"
                    :placeholder="'12.12.2012'"
                    :blockSize="'100px'"
                    :blockHeight="'19px'"
                    :inputType="'date'"
                    :displayStyles="'font-bold text-[13px] text-[#177E89] w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] font-bold m-0 p-0 relative top-[-3px] bg-transparent border-none outline-none active:outline-none'"
                />
                <div class="opacity-70 text-[11px]">дата народження</div>
            </div>
            <div class="flex justify-between text-[#177E89] w-full items-center my-[16px] relative top-[1px]">
                <data-input 
                    :modelValue="phoneNumber"
                    @update:modelValue="$emit('update:phoneNumber', $event)"
                    :placeholder="'Номер'"
                    :blockSize="'100px'"
                    :blockHeight="'19px'"
                    :displayStyles="'font-bold text-[13px] text-[#177E89] w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] font-bold m-0 p-0 relative top-[-3px] bg-transparent border-none outline-none active:outline-none'"
                />
                <div class="opacity-70 text-[11px]">номер</div>
            </div>
            <div class="flex justify-between text-[#177E89] w-full items-center my-[16px]">
                <data-input 
                    :modelValue="email"
                    @update:modelValue="$emit('update:email', $event)"
                    :placeholder="'Email'"
                    :blockSize="'100px'"
                    :blockHeight="'19px'"
                    :displayStyles="'font-bold text-[13px] text-[#177E89] w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] font-bold m-0 p-0 relative top-[-3.5px] bg-transparent border-none outline-none active:outline-none'"
                />
                <div class="opacity-70 text-[11px]">email</div>
            </div>
            <div class="flex justify-between text-[#177E89] w-full items-center my-[16px] ">
                <div class="font-bold text-[13px]">
                    {{nationality}}
                </div>
                <div class="opacity-70 text-[11px]">національність</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import DataInput from '../ui/DataInput.vue'
    
    export default {
        name: 'info-card',

        props: {
            surname: {
                type: String,
                default: '',
                required: true
            },
            name: {
                type: String,
                default: '',
                required: true
            },
            patronymic: {
                type: String,
                default: '',
                required: true
            },
            birthDate: {
                type: String,
                default: '',
                required: true
            },
            phoneNumber: {
                type: String,
                default: '',
                required: true
            },
            email: {
                type: String,
                default: '',
                required: true
            },
            group: {
                type: String,
                default: '',
                required: true
            },
            studentPhoto: {
                type: [String, null],
                default: null,
                required: true
            },
            nationality: {
                type: String,
                default: '',
                required: true
            },
            studentNumber: {
                type: String,
                default: '',
                required: true
            },
            studentAmplua: {
                type: String,
                default: '',
                required: true
            }
        },

        setup(props) {
            const fileInput = ref(null)
            const inputValue = ref(null)
            const photo = ref(props.studentPhoto)

            const uploadPhoto = () => {
                fileInput.value.click()
            }

            onMounted(() => {
                console.log(typeof photo.value)
                if(photo.value === null || photo.value === 'null') {
                    photo.value = require('@/assets/student-photo-placeholder-tpbg.png')
                }
            })

            watch(() => props.studentPhoto, (first, second) => {
                photo.value = first
                inputValue.value = null
            })

            return {
                uploadPhoto,
                photo,
                fileInput,
                inputValue
            }
        },

        components: {
            DataInput
        },
    }
</script>
<template>
    <div class="bg-[#211C1F] w-[400px] h-[181px] rounded-[10px] py-3 mb-[24px] border-[1px] border-[#FFF] border-opacity-10">
        <div class="w-full text-center mb-[20px]">
            <div class="flex text-center items-center py-[1px] bg-[#FECC00] w-[95%] rounded-[10px] mx-auto">
                <div class="block-title text-[11px] font-bold text-[#211C1F] text-center mx-auto w-[60%]">ПАРАМЕТРИ</div>
            </div>
        </div>
        <div class=" mx-[27px] text-[#177E89] flex items-center mt-3">
            <div class="flex justify-between w-[40%] items-center h-[19px]">
                <!-- <div class="font-bold text-[13px]">
                    m.bertsyk
                </div> -->
                <data-input 
                    :modelValue="login"
                    @update:modelValue="$emit('update:login', $event)"
                    :placeholder="'Логін'"
                    :blockSize="'100px'"
                    :displayStyles="'text-[13px] text-[#177E89] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] p-0 m-0 w-[100px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
                />
                <div class="text-sm opacity-70 text-[11px]">
                    логін
                </div>
            </div>
            <div class="w-[20%]"></div>
            <div class="flex justify-between w-[40%] items-center h-[19px]">
                <data-input 
                    :modelValue="password"
                    @update:modelValue="$emit('update:password', $event)"
                    :placeholder="'Пароль'"
                    :blockSize="'100px'"
                    :displayStyles="'text-[13px] text-[#177E89] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] p-0 m-0 w-[100px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
                />
                <div class="text-sm opacity-70 text-[11px]">
                    пароль
                </div>
            </div>
        </div>
        <div class="h-[0.5px] bg-[#FECC00] mt-2 mx-[27px] opacity-70"></div>
        <div class="mx-[27px] text-[#177E89] flex items-center mt-3 justify-between">
            <div class="flex justify-between w-[35%] items-center h-[19px]">
                <data-input 
                    :modelValue="playerNumber"
                    @update:modelValue="$emit('update:playerNumber', $event)"
                    :placeholder="'0'"
                    :blockSize="'30px'"
                    :displayStyles="'text-[13px] text-[#177E89] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] p-0 m-0 w-[30px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
                />
                <div class="text-sm opacity-70 text-[11px]">
                    номер гравця
                </div>
            </div>
            <div class="flex justify-between w-[30%] items-center h-[19px]">
                <data-input 
                    :modelValue="amplua"
                    @update:modelValue="$emit('update:amplua', $event)"
                    :placeholder="'НП'"
                    :blockSize="'30px'"
                    :displayStyles="'text-[13px] text-[#177E89] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] p-0 m-0 w-[30px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
                />
                <div class="text-sm opacity-70 text-[11px]">
                    амплуа
                </div>
            </div>
        </div>
        <div class="mx-[27px] text-[#177E89] flex items-center justify-between mt-3">
            <div class="flex justify-between w-[20%] items-center h-[19px]">
                <data-input 
                    :modelValue="height"
                    @update:modelValue="$emit('update:height', $event)"
                    :placeholder="'000.00'"
                    :blockSize="'30px'"
                    :displayStyles="'text-[13px] text-[#177E89] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] p-0 m-0 w-[30px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
                />
                <div class="text-sm opacity-70 text-[11px]">
                    зріст
                </div>
            </div>
            <div class="flex justify-between w-[20%] items-center h-[19px]">
                <data-input 
                    :modelValue="weight"
                    @update:modelValue="$emit('update:weight', $event)"
                    :placeholder="'00.0'"
                    :blockSize="'15px'"
                    :displayStyles="'text-[13px] text-[#177E89] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] p-0 m-0 w-[15px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
                />
                <div class="text-sm opacity-70 text-[11px]">
                    вага (кг)
                </div>
            </div>
            <div class="flex justify-between w-[30%] items-center h-[19px]">
                <data-input 
                    :modelValue="breastVolume"
                    @update:modelValue="$emit('update:breastVolume', $event)"
                    :placeholder="'00.0'"
                    :blockSize="'15px'"
                    :displayStyles="'text-[13px] text-[#177E89] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] p-0 m-0 w-[15px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
                />
                <div class="text-sm opacity-70 text-[11px]">
                    об'єм грудей
                </div>
            </div>
        </div>
        <div class="mx-[27px] text-[#177E89] flex items-center justify-between mt-3">
            <div class="flex justify-between w-[20%] items-center h-[19px] mt-[-2px]">
                <data-input 
                    :modelValue="leadingFoot"
                    @update:modelValue="$emit('update:leadingFoot', $event)"
                    :placeholder="'Ліва'"
                    :blockSize="'40px'"
                    :displayStyles="'text-[13px] text-[#177E89] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] p-0 m-0 w-[40px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
                />
                <div class="text-sm opacity-70 text-[11px]">
                    нога
                </div>
            </div>
            <div class="flex justify-between w-[20%] items-center h-[19px]">
                <data-input 
                    :modelValue="footSize"
                    @update:modelValue="$emit('update:footSize', $event)"
                    :placeholder="'00.0'"
                    :blockSize="'30px'"
                    :displayStyles="'text-[13px] text-[#177E89] w-[30px] font-semibold text-white w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] p-0 m-0 w-[30px] font-semibold bg-transparent border-none relative top-[-1px] outline-none active:outline-none'"
                />
                <div class="text-sm opacity-70 text-[11px]">
                    р. ноги
                </div>
            </div>
            <div class="flex justify-between w-[30%] items-center text-center">
                <data-input 
                    :modelValue="startTraining"
                    @update:modelValue="$emit('update:startTraining', $event)"
                    :placeholder="'12.12.2012'"
                    :blockSize="'42px'"
                    :inputType="'date'"
                    :blockHeight="'19px'"
                    :displayStyles="'text-[13px] text-[#177E89] w-[100%] font-semibold text-white w-full bg-transparent border-none'"
                    :inputStyles="'text-[#FECC00] text-start text-[13px] p-0 m-0 w-[75px] font-semibold bg-transparent border-none outline-none relative top-[-3px] active:outline-none'"
                />
                <div class="text-sm opacity-70 text-[11px]">
                    старт
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DataInput from '../ui/DataInput.vue'

    export default {
        name: 'parameters-card',

        props: {
            login: {
                type: String,
                default: ''
            },
            password: {
                type: String,
                default: ''
            },
            playerNumber: {
                type: Number,
                default: 0
            },
            amplua: {
                type: String,
                default: ''
            },
            height: {
                type: Number,
                default: 0
            },
            weight: {
                type: Number,
                default: 0
            },
            breastVolume: {
                type: Number,
                default: 0
            },
            leadingFoot: {
                type: String,
                default: ''
            },
            footSize: {
                type: Number,
                default: 0
            },
            startTraining: {
                type: String,
                default: ''
            }
        },

        components: {
            DataInput
        }
    }
</script>

<style lang="scss" scoped>
    .block-title { 
        font-family: 'Avenir Cyrillic Bold';
    }
</style>
<template>
    <div @click="$emit('close')" style="background-color: rgba(53, 53, 53, 0.10)" class="backdrop-blur-[2px] fixed w-full h-[130%] top-[-70px] mt-[-10px]"></div>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#211C1F] w-[300px] rounded-[10px] p-[14px] z-20 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px] border-[1px] border-[#FFF] border-opacity-10">
        <div class="text-[#8C8C8C] font-medium text-[14px]">
            назва локації
        </div>
        <div class="text-[#000] mb-[14px] rounded-[6px] w-full py-[5px]">
            <input v-model="locationMV.name" type="text" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
        </div>
        <div class="text-[#8C8C8C] font-medium text-[14px]">
            скорочена назва локації
        </div>
        <div class="text-[#000] mb-[14px] rounded-[6px] w-full py-[5px]">
            <input v-model="locationMV.small_name" type="text" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
        </div>
        <div class="text-[#8C8C8C] font-medium text-[14px]">
            адреса локації
        </div>
        <div class="text-[#000] mb-[14px] rounded-[6px] w-full py-[5px]">
            <input v-model="locationMV.address" type="text" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
        </div>
        <div class="text-[#8C8C8C] font-medium mb-[4px] text-[14px]">
            посилання на карту
        </div>
        <div class="mb-[14px]">
            <url-component v-model="locationMV.geo_url" placeholder="Гео-точка" />
        </div>
        <div class="text-[#8C8C8C] font-medium text-[14px]">
            опис локації
        </div>
        <div class="text-[#000] rounded-[6px] w-full py-[5px]">
            <textarea v-model="locationMV.description" type="text" class="scrolltab w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none" rows="5"></textarea>
        </div>
        <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
            фото локації
        </div>
        <div class="w-full mb-[14px] bg-gray-400 relative rounded-[10px] h-[120px]">
            <div class="absolute opacity-0 hover:opacity-100 top-0 right-0">
                <div class="flex bg-white p-2 rounded-bl-[10px] rounded-tr-[10px]">
                    <img @click="selectLocationPhoto" class="mr-[7px] w-[15px] cursor-pointer" src="@/assets/icons/upload_icon.svg">
                    <input class="hidden" type="file">
                    <img @click="deleteLocationPhoto" class="w-[15px] cursor-pointer" src="@/assets/icons/trash_icon.svg">
                </div>
            </div>
            <img v-if="locationPhoto" :src="locationPhotoDataUrl" class="w-full h-full object-cover rounded-[10px]">
        </div>
        <div>
            <button @click="updateLocationVM" class="bg-[#FECC00] text-[#211C1F] text-center w-full rounded-md py-1 hover:bg-opacity-90">Змінити</button>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import UrlComponent from './UrlComponent.vue'
    import { updateLocation } from '@/services/apiRequests'

    export default {
        props: {
            location: {
                type: Object,
                default: () => ({})
            }
        },

        emits: ['close', 'updateLocations'],
        
        setup(props, { emit }) {
            const locationMV = ref(Object.assign({}, props.location))
            const locationPhoto = ref(props.location.photo)
            const locationPhotoDataUrl = ref(process.env.VUE_APP_API_URL + 'static/' + props.location.photo)

            const sendToMap = () => {
                const mapQuery = locationMV.address.split(' ').join('%20')
                window.open(`https://www.openstreetmap.org/search?query=${mapQuery}`, '_blank')
            }

            const selectLocationPhoto = () => {
                const input = document.querySelector('input[type="file"]')
                input.click()
                input.addEventListener('change', () => {
                    locationPhoto.value = input.files[0]
                    locationPhotoDataUrl.value = URL.createObjectURL(input.files[0])
                })
            }

            const deleteLocationPhoto = () => {
                locationPhoto.value = null
                locationPhotoDataUrl.value = null
            }

            const updateLocationVM = async () => {
                await updateLocation(
                    locationMV.value.location_id,
                    locationMV.value.name,
                    locationMV.value.small_name,
                    locationMV.value.address,
                    locationMV.value.description,
                    locationMV.value.geo_url,
                    typeof locationPhoto.value == 'string' ? 'selected' : locationPhoto.value 
                )
                emit('close')
                emit('updateLocations')
                console.log('update')
            }

            return {
                locationPhoto,
                locationPhotoDataUrl,
                sendToMap,
                selectLocationPhoto,
                deleteLocationPhoto,
                locationMV,
                updateLocationVM
            }
        },

        components: {
            UrlComponent
        }
    }
</script>

<style>

    .scrolltab::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .scrolltab::-webkit-scrollbar-track {
        background: #ededed;        /* цвет зоны отслеживания */
    }

    .scrolltab::-webkit-scrollbar-thumb {
        background-color: #a6a4a4;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }

</style>


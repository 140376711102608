<template>
    <div class="absolute shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] top-[50%] left-[50%] p-4 bg-[#211C1F] rounded-[10px] mt-[-20px] w-[250px]">
        <div class="text-center mb-3 text-[#FFF]">Ви дійсно хочете видалити локацію {{ location.small_name }}?</div>
        <div class="flex justify-between">
            <button @click="deleteLocation" class="bg-[#BD252C] hover:bg-opacity-90 text-white px-4 py-1 rounded-[10px]">Так</button>
            <button @click="$emit('close')" class="px-4 py-1 rounded-[10px] bg-[#FECC00]">Ні</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'delete-location-form',

        props: {
            location: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const deleteLocation = () => {
                emit('deleteLocation', props.location)
            }

            return {
                deleteLocation
            }
        }
    }
</script>

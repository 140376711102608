<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#211C1F] w-[400px] h-[500px] rounded-[10px] pt-[10px] border-[1px] border-[#FFF] border-opacity-10">
        <div class="bg-[#FECC00] text-[13px] font-bold text-center rounded-[4px] mx-[14px] py-[2px]">
            ФІНАНСИ
        </div>
        <div class="mt-[10px]">
            <div class="flex flex-col items-center">
                <div class="text-[#BD252C] text-[26px] font-bold block-title">
                    <span class="font-['Avenir Cyrillic2']">{{ studentBalanceState.toFixed(2) }}</span><span class="font-sans">₴</span>
                </div>
                <div class="text-gray-300 text-[12px] font-light">
                    учнівський баланс
                </div>
            </div>
        </div>
        <div class="mt-[10px]">
            <div class="flex flex-col items-center">
                <div class="text-[#BD252C] text-[26px] font-bold block-title">
                    <span class="font-['Avenir Cyrillic2']">{{ serviceBalanceState.toFixed(2) }}</span><span class="font-sans">₴</span>
                </div>
                <div class="text-gray-300 text-[12px] font-light">
                    сервісний баланс
                </div>
            </div>
        </div>

        <div class="mt-[20px]">
            <div class="flex items-center justify-between mx-[30px]">
                <div class="text-white flex items-center text-[12px] font-semibold">
                    <div class="mr-[5px]">
                        оплати
                    </div>
                    <div @click="$router.push('/incomes/balance_id/' + studentBalanceId)" class="px-4 py-0.5 cursor-pointer bg-[#FECC00] text-[12px] text-center text-black font-bold rounded-md ">
                        переглянути
                    </div>
                </div>
                <div class="px-4 py-0.5 bg-[#FECC00] text-[12px] text-center text-black font-bold rounded-md ">
                    2023
                </div>
            </div>
    
            <div class="scrolltab w-[95%] mt-[9px] flex flex-col items-center overflow-auto h-[110px]">
                <div v-if="allTrainersIncomes.length > 0" v-for="transaction in allTrainersIncomes" class="flex mt-2 justify-between text-[#FFF] items-center w-[80%]">
                    <div class="mr-2 w-[30px] text-[10px]">{{ formateDate(transaction.date) }}</div>
                    <div class="text-[12px] w-[170px] font-bold">{{ getStudentName(transaction.label) }}</div>
                    <div class="mr-[3px] w-[30px] text-[#48E5C2] text-end font-bold text-[12px]">{{ transaction.amount.toFixed(2) }}</div>
                </div>
                <div v-else>
                    <div class="text-[#FECC00] relative top-[50px] text-[12px] font-bold">
                        Немає оплат
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[15px]">
            <div class="flex items-center justify-between mx-[30px]">
                <div class="text-white flex items-center text-[12px] font-semibold">
                    <div class="mr-[5px]">
                        транзакції
                    </div>
                </div>
                <div class="px-4 py-0.5 bg-[#FECC00] text-[12px] text-center text-black font-bold rounded-md ">
                    2023
                </div>
            </div>

            <div class="scrolltab w-[95%] mt-[9px] flex flex-col items-center overflow-auto h-[110px]">
                <div v-if="allTrainerTransactions.length > 0" v-for="transaction in allTrainerTransactions" class="flex mt-2 justify-between text-[#FFF] items-center w-[83%]">
                    <div class="text-sm truncate w-[73px] text-[10px]">{{ formateDate(transaction.date) }}</div>
                    <div class="font-bold text-sm truncate w-[150px] text-[12px]">{{ transaction.label }}</div>
                    <div 
                        class="font-bold text-sm truncate pl-[10px] w-[90px] text-end text-[12px]"
                        :class="{
                            'text-[#BD252C]' : transaction.sender_balance_id == studentBalanceId || transaction.sender_balance_id == serviceBalanceId,
                            'text-[#48E5C2]' : transaction.receiver_balance_id == studentBalanceId || transaction.receiver_balance_id == serviceBalanceId
                        }"    
                    >
                        <span>{{ transaction.sender_balance_id == studentBalanceId || transaction.sender_balance_id == serviceBalanceId ? '-' : '+' }}</span>
                        <span>{{ transaction.amount.toFixed(2) }}</span>
                    </div>
                </div>
                <div v-else>
                    <div class="text-[#FECC00] relative top-[50px] text-[12px] font-bold">
                        Немає транзакцій
                    </div>
                </div>
            </div>

        </div>


    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getRealIncomesByBalanceIdRequest, getTransactionsByBalanceIdRequest } from '@/services/apiRequests'

    export default {
        name: 'FinanceCard',

        props: {
            studentBalanceState: {
                type: Number,
                default: 0
            },
            serviceBalanceState: {
                type: Number,
                default: 0
            },
            studentBalanceId: {
                type: String,
                default: ''
            },
            serviceBalanceId: {
                type: String,
                default: ''
            },
            
        },

        setup(props) {
            const allTrainersIncomes = ref([])
            const allTrainerTransactions = ref([])

            onMounted(async () => {
                console.log('props.studentBalanceId ----- ', props.studentBalanceId)
                allTrainersIncomes.value = (await getRealIncomesByBalanceIdRequest(props.studentBalanceId)).data.real_incomes
                console.log('allTrainersIncomes.value ----- ', allTrainersIncomes.value)

                const resStudentBalance = await getTransactionsByBalanceIdRequest(props.studentBalanceId)
                const resServiceBalance = await getTransactionsByBalanceIdRequest(props.serviceBalanceState)
                allTrainerTransactions.value = [...resStudentBalance.data.transactions, ...resServiceBalance.data.transactions]
            })

            const getStudentName = (transaction_label) => {
                const transactionLabelArray = transaction_label.split(' ')
                return `${transactionLabelArray[transactionLabelArray.length - 1]} ${transactionLabelArray[transactionLabelArray.length - 2]}`
            }

            const formateDate = (date) => {
                const dateArray = date.split('T')[0].split('-')
                return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`
            }
            
            return { 
                allTrainersIncomes,
                allTrainerTransactions,
                getStudentName,
                formateDate
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
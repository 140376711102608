<template>
    <div class="mx-2 mt-2">
        <div class="student-base-info flex justify-between">
            <div class="flex">
                <div class="student-base-info__photo rounded-full">
                    <img :src="studentPhoto" alt="photo" @error="studentPhoto = require('@/assets/student-photo-placeholder.png')" width="100" height="100" class="rounded-full">
                </div>
                <div class="ml-2">
                    <div class="student-base-info__name text-xl font-semibold">
                        <div>
                            {{currentStudent.name}} {{currentStudent.surname}}
                        </div>
                    </div>
                    <div class="student-base-info__group">
                        <div>{{currentStudent.group_name}}</div  >
                    </div>
                </div>
            </div>
            <div class="">
                <div class="text-xl">
                    Баланс: <span class="font-semibold" :class="{ 'text-[#BD252C]' : studentBalance < 0, 'text-green-600' : studentBalance > 0}">{{studentBalance}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useRoute } from 'vue-router'
    import { getCurrentStudent, getBalanceState } from './service/apiRequests'
    export default {
        name: 'current-user',

        setup() {
            const currentStudent = ref({})
            const studentPhoto = ref('')
            const route = useRoute()
            const studentBalance = ref(0)

            onMounted(async () => {
                currentStudent.value = await getCurrentStudent(route.params.id)
                studentPhoto.value = process.env.VUE_APP_API_URL + 'static/' + currentStudent.value.photo
                studentBalance.value = await getBalanceState(currentStudent.value.balance_id)
            })


            return {
                currentStudent,
                studentPhoto,
                studentBalance
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div class="bg-white rounded-[10px] flex flex-col items-center justify-center px-2 py-3">
        <div>
            Зберегти зміни в картці тренера?
        </div>
        <div class="flex w-full justify-between">
            <button @click="$emit('confirmUpdate')" class="bg-[#FECC00] text-[#211C1F] rounded-[10px] px-[20px] py-[10px] mt-[10px]">
                Так
            </button>
            <button @click="$emit('close')" class="bg-[#BD252C] text-[#211C1F] rounded-[10px] px-[20px] py-[10px] mt-[10px]">
                Ні
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'confirm-update-form',
    }
</script>
